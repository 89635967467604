import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormComponent } from './components/form/form.component';
import { FormElementComponent } from './components/form-elements/form-element.component';
import { BasicFormElementComponent } from './components/form-elements/_basic-form-element/form-basic-component.component';
import { FormInputComponent } from './components/form-elements/form-input/form-input.component';
import { SharedModule } from '../shared/shared.module';
import { FormCheckboxComponent } from './components/form-elements/form-checkbox/form-checkbox.component';
import { FormSelectComponent } from './components/form-elements/form-select/form-select.component';
import { FormChannelSettingsComponent } from './components/form-elements/form-channel-settings/form-channel-settings.component';
import { FormTextareaComponent } from './components/form-elements/form-textarea/form-textarea.component';
import { FormAssetComponent } from './components/form-elements/form-asset/form-asset.component';
import { FormPersonComponent } from './components/form-elements/form-person/form-person.component';
import { FormExcelWorksheetComponent } from './components/form-elements/form-excel-worksheet/form-excel-worksheet.component';
import { FormChannelComponent } from './components/form-elements/form-channel/form-channel.component';
import { FormRadioComponent } from './components/form-elements/form-radio/form-radio.component';
import { FormSortableSelectComponent } from './components/form-elements/form-sortable-select/form-sortable-select.component';
import { FormColorComponent } from './components/form-elements/form-color/form-color.component';
import { FormPillsComponent } from './components/form-elements/form-pills/form-pills.component';
import { FormExcelAssetComponent } from './components/form-elements/form-excel-asset/form-excel-asset.component';
import { HighlightDirective } from './directives/highlight.directive';
import { FormStaticTextComponent } from './components/form-elements/form-static-text/form-static-text.component';
import { FormStaticHeadingComponent } from './components/form-elements/form-static-heading/form-static-heading.component';
import { FormSelectPublicationComponent } from './components/form-elements/form-select-publication/form-select-publication.component';
import { FormDateComponent } from './components/form-elements/form-date/form-date.component';
import { FormSelectSharedStorageComponent } from './components/form-elements/form-select-shared-storage/form-select-shared-storage.component';
import { FormSideNavButtonsComponent } from './components/form-side-nav-buttons/form-side-nav-buttons.component';

@NgModule({
    declarations: [
        FormComponent,
        FormElementComponent,
        FormInputComponent,
        FormTextareaComponent,
        FormAssetComponent,
        BasicFormElementComponent,
        FormCheckboxComponent,
        FormSelectComponent,
        FormChannelSettingsComponent,
        FormPersonComponent,
        FormExcelWorksheetComponent,
        FormChannelComponent,
        FormRadioComponent,
        FormSortableSelectComponent,
        FormColorComponent,
        FormPillsComponent,
        FormExcelAssetComponent,
        HighlightDirective,
        FormStaticHeadingComponent,
        FormStaticTextComponent,
        FormSelectPublicationComponent,
        FormDateComponent,
        FormSelectSharedStorageComponent,
        FormSideNavButtonsComponent,
    ],
    imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule],
    exports: [FormComponent, FormSideNavButtonsComponent],
})
export class EliasFormsModule {}
