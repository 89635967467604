import { Injectable } from '@angular/core';
import { BehaviorSubject, NEVER, Observable, of, timer } from 'rxjs';
import { Token } from '../models/token.model';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { DomainService } from '../../modules/core/services/domain.service';
import { HttpClient } from '@angular/common/http';
import { JwtService } from './jwt.service';
import { LocalStorageKeys } from '../../modules/shared/enums/local-storage-keys.enum';

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    public autorefreshToken$: Observable<any>;

    private readonly REFRESH_TOKEN_OFFSET = -10 * 1000;
    private hasValidToken$ = new BehaviorSubject(false);

    constructor(private domainService: DomainService, private http: HttpClient, private jwtService: JwtService) {
        this.hasValidToken$.next(!this.isTokenExpired());

        const userLoggedOut$ = this.hasValidToken$.pipe(filter((isValid) => !isValid));
        this.autorefreshToken$ = this.hasValidToken$.pipe(
            filter(Boolean),
            switchMap(() =>
                timer(this.getTokenExpirationTime(this.REFRESH_TOKEN_OFFSET)).pipe(takeUntil(userLoggedOut$))
            ),
            switchMap(() => this.refreshToken())
        );
    }

    public persistToken(token: string): void {
        localStorage.setItem(LocalStorageKeys.Token, token);
        this.hasValidToken$.next(true);
    }

    public removeToken(): void {
        localStorage.removeItem(LocalStorageKeys.Token);
        this.hasValidToken$.next(false);
    }

    public refreshToken(): Observable<any> {
        if (this.isTokenExpired()) {
            this.removeToken();
            return NEVER;
        }

        return this.http.get<Token>(this.domainService.apiBaseUrl + '/refreshtoken').pipe(
            map((response: Token) => {
                if (response.token) {
                    this.persistToken(response.token);
                } else {
                    this.removeToken();
                }
            }),
            catchError(() => {
                this.removeToken();
                return of();
            })
        );
    }

    public isTokenExpired(): boolean {
        return this.jwtService.isTokenExpired(this.getToken());
    }

    public getToken(): string {
        return localStorage.getItem(LocalStorageKeys.Token);
    }

    public getTokenLifetime(): number {
        return this.jwtService.getTokenLifeTime(this.getToken());
    }

    private getTokenExpirationTime(offset = 0): Date {
        return this.jwtService.getTokenExpirationTime(this.getToken(), offset);
    }
}
