import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Asset } from '../../../../modules/shared/models/asset.model';
import { Observable } from 'rxjs';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { AssetForm } from '../../forms/asset.form';
import { TranslateService } from '@ngx-translate/core';
import { delay, first, switchMap, tap } from 'rxjs/operators';
import { UrlParamsHandlerInterface } from '../../../../modules/links/interfaces/url-params-handler.interface';
import { ExcelUpdateListener } from '../../services/excel-update-listener.service';
import { UrlParamsService } from '../../../../modules/links/services/url-params.service';
import { AssetManagementSidenavComponent } from '../asset-management-sidenav/asset-management-sidenav.component';
import { AssetBrowserQuery } from '../../state/asset-browser/asset-browser.query';
import { AssetCroppingResultDto } from '../../../image-cropping/types';

@Component({
    selector: 'elias-assetbrowser-files',
    styleUrls: ['./asset-browser-files.component.scss'],
    templateUrl: './asset-browser-files.component.html',
})
export class AssetBrowserFilesComponent implements OnInit, UrlParamsHandlerInterface {
    @Input() assets: Asset[] = [];
    @Input() fixedHeight = true;
    @Input() selectedAssets: Asset[] = [];
    @Input() viewValue = 0;

    @Output() clearSelection = new EventEmitter();
    @Output() clickAsset = new EventEmitter<Asset>();
    @Output() fileReplaced = new EventEmitter<Asset>();
    @Output() assetClonedAndCropped = new EventEmitter<AssetCroppingResultDto>();
    @Output() moreAssetsRequested = new EventEmitter<void>();

    public assetsLoading$ = this.assetBrowserQuery.selectLoading();
    public assetsLoaded$ = this.assetBrowserQuery.select('loaded');

    constructor(
        public assetBrowserQuery: AssetBrowserQuery,
        public translateService: TranslateService,
        private assetForm: AssetForm,
        private excelUpdateListener: ExcelUpdateListener,
        private sideNavService: SideNavService,
        private urlParamsService: UrlParamsService
    ) {}

    ngOnInit() {
        this.assetsLoaded$.pipe(switchMap(() => this.handleUrlParams())).subscribe();
        this.excelUpdateListener.excelUpdate$.subscribe();
    }

    public handleUrlParams(): Observable<any> {
        return this.urlParamsService.getParamFromURL('asset').pipe(
            delay(400),
            first(),
            tap(({ value: assetId }) => {
                const asset = this.assetBrowserQuery.getEntity(assetId);
                if (asset !== undefined) {
                    this.onAssetClick(asset);
                }
            })
        );
    }

    public onAssetClick(asset: Asset): void {
        if (!this.fixedHeight) {
            const onSave = new EventEmitter<any>();
            const onDelete = new EventEmitter<any>();
            const onReplace = new EventEmitter<Asset>();

            const inputs = {
                assetId: asset.id,
                formConfig: this.assetForm.getConfiguration(),
            };
            const outputs = {
                save: onSave,
                delete: onDelete,
                replace: onReplace,
            };
            this.sideNavService.setComponent(AssetManagementSidenavComponent, inputs, outputs);

            onSave.subscribe((result: any | AssetCroppingResultDto) => {
                if (result.hasOwnProperty('replaced')) {
                    this.assetClonedAndCropped.emit(result);
                }
            });

            onDelete.subscribe((value) => {
                this.selectedAssets = [];
                this.clearSelection.emit();
            });

            onReplace.subscribe((value) => {
                this.fileReplaced.emit(value);
            });
        } else {
            this.clickAsset.emit(asset);
        }
    }

    public isSelected(asset: Asset): boolean {
        return this.selectedAssets.map((a: Asset) => a.id).includes(asset.id);
    }

    public trackAssetById(index: number, asset: Asset): string {
        return asset.id;
    }

    loadMoreAssets(): void {
        this.moreAssetsRequested.emit();
    }
}
