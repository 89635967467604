<div
    class="position-loader"
    data-cy="loading"
    data-karma="loading"
    [ngClass]="{ centered: centered, inverted: inverted }">
    <div class="dots dot-size-{{ dotSize }}" [ngClass]="{ colorful: isColorful, white: !isColorful }">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
    </div>
    <div class="loading-content" *ngIf="includeText">
        <h1 class="title">{{ 'loading.loadingTitle' | translate }}</h1>
    </div>
    <div class="loading-content" *ngIf="descriptionContext">
        <p class="description">{{ 'loading.' + descriptionContext + 'LoadingDescription' | translate }}</p>
    </div>
</div>
