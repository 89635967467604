<ng-container *ngIf="renderType === 'normal'">
    <div [formGroup]="formGroup">
        <mat-form-field
            appearance="outline"
            [hideRequiredMarker]="false"
            [ngClass]="{ 'color-select': type === 'normal' }">
            <mat-label>{{ label }}</mat-label>
            <mat-select [formControlName]="name" [required]="required" [(value)]="selectedValue">
                <ng-container *ngFor="let option of options; trackBy: trackByIndex">
                    <mat-option [value]="option.key">
                        <div class="options">
                            <div class="colors">
                                <ng-container *ngIf="type === 'theme'">
                                    <ng-container *ngFor="let color of option.colors; trackBy: trackByIndex">
                                        <div
                                            class="colored-ball"
                                            [ngStyle]="{
                                                'background-color': getCorrectFormat(color)
                                            }"></div>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="type === 'normal'">
                                    <div
                                        class="colored-ball"
                                        [ngStyle]="{
                                            'background-color': getCorrectFormat(option.key)
                                        }"></div>
                                </ng-container>
                            </div>
                            <div>
                                <p>{{ option.value }}</p>
                            </div>
                        </div>
                    </mat-option>
                </ng-container>
            </mat-select>
            <ng-container *ngIf="selectedValue">
                <ng-container *ngIf="type === 'normal'">
                    <div
                        class="colored-ball"
                        [ngStyle]="{ 'background-color': getCorrectFormat(selectedValue) }"
                        [ngClass]="{ 'position-control': selectedValue }"></div>
                </ng-container>
            </ng-container>
            <mat-error *ngIf="formGroup.get(name).hasError('required')">
                {{ 'error.' + name | translate }}
            </mat-error>
            <mat-hint>{{ hint | translate }}</mat-hint>
        </mat-form-field>
    </div>
</ng-container>

<ng-container *ngIf="renderType === 'text'">
    <div [formGroup]="formGroup">
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field appearance="outline" [hideRequiredMarker]="false" [ngClass]="{ 'has-prefix': matPrefix }">
                <mat-label>{{ label }}</mat-label>
                <span matPrefix *ngIf="matPrefix" [ngClass]="{ 'mat-prefix': matPrefix }">{{ matPrefix }} &nbsp;</span>
                <input matInput [formControlName]="name" [placeholder]="placeholder" [required]="required" #input />
                <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
                <mat-error *ngIf="formGroup.get(name).hasError('required')">
                    {{ 'error.color' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="formGroup.get(name).hasError('maxlength') && !formGroup.get(name).hasError('required')">
                    {{ 'error.maxlength.color' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="formGroup.get(name).hasError('minlength') && !formGroup.get(name).hasError('required')">
                    {{ 'error.minlength.color' | translate }}
                </mat-error>
                <mat-error *ngIf="formGroup.get(name).hasError('pattern') && !formGroup.get(name).hasError('required')">
                    {{ 'error.pattern.color' | translate }}
                </mat-error>
            </mat-form-field>
            <div class="circle" [ngStyle]="{ background: '#' + input.value }"></div>
        </div>
    </div>
</ng-container>
