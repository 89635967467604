import { Publication } from '../../models/publication.model';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { PublicationStatistics } from '../../../modules/shared/models/publication-statistics.model';

export interface PublicationsState extends EntityState<Publication>, ActiveState {
    totalNumberOfPages: number;
    currentPage: number;
    totalDataLength: number;
    hasNextPage: boolean;
    loaded: boolean;
    statistics: PublicationStatistics;
}

const initialState: Partial<PublicationsState> = {
    totalNumberOfPages: 0,
    currentPage: 1,
    totalDataLength: 0,
    hasNextPage: false,
    loaded: false,
    statistics: {
        comments: {
            total: 0,
            unresolved: 0,
        },
        needsReview: 0,
    },
};

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Publications', resettable: true })
export class PublicationsStore extends EntityStore<PublicationsState, Publication> {
    constructor() {
        super(initialState);
    }
}
