import { Injectable } from '@angular/core';
import { PublicationGroup } from '../models/publication-group.model';
import { PublicationGroupsStore } from '../state/publication-groups/publication-groups.store';
import { Observable, throwError } from 'rxjs';
import { PublicationGroupsQuery } from '../state/publication-groups/publication-groups.query';
import { DomainService } from '../../modules/core/services/domain.service';
import { HttpClient } from '@angular/common/http';
import { ID, withTransaction } from '@datorama/akita';
import { catchError, tap } from 'rxjs/operators';
import { Publication } from '../../publication/models/publication.model';
import { PublicationsStore } from '../../publication/state/publications/publications.store';
import { RequestDto } from '../../editor/editor/models/dto/request-dto.model';
import { NewPublicationGroupDto } from '../../publication/models/new-publication-group-dto.model';
import { SortingOption } from '../../modules/shared/models/sorting-option.model';

@Injectable({
    providedIn: 'root',
})
export class PublicationGroupService {
    constructor(
        private publicationGroupStore: PublicationGroupsStore,
        private publicationGroupQuery: PublicationGroupsQuery,
        private publicationStore: PublicationsStore,
        private domainService: DomainService,
        private http: HttpClient
    ) {}

    add(publicationGroup: PublicationGroup) {
        this.publicationGroupStore.add(publicationGroup);
    }

    getPublicationsFromGroup(id: ID): Observable<any> {
        const url = `${this.domainService.apiBaseUrl}/publication-groups/${id}/publications`;

        return this.http.get<Publication>(url);
    }

    getPublicationGroupsWithoutUpdatingStore(options: any = {}): Observable<any> {
        this.publicationGroupStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/publication-groups`;

        return this.http.get<any>(url, { params: options });
    }

    getPublicationGroups(options: any = {}, resetStore = true): Observable<any> {
        this.publicationGroupStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/publication-groups`;

        return this.http.get<any>(url, { params: options }).pipe(
            withTransaction((result) => {
                this.publicationGroupStore.update({
                    currentPage: result.currentPage,
                    totalNumberOfPages: result.totalNumberOfPages,
                    totalDataLength: result.totalDataLength,
                    hasNextPage: result.hasNextPage,
                });

                if (resetStore) {
                    this.publicationGroupStore.set(result.data);
                } else {
                    this.publicationGroupStore.add(result.data);
                }

                this.publicationGroupStore.setLoading(false);
                this.publicationGroupStore.update({ loaded: true });
            })
        );
    }

    createPublicationGroup(publicationGroup: NewPublicationGroupDto) {
        this.publicationGroupStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/publication-groups`;
        return this.http.post<PublicationGroup>(url, publicationGroup).pipe(
            tap((pubGroup) => {
                this.publicationGroupStore.add(pubGroup);
                this.publicationGroupStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getSinglePublicationGroup(id) {
        const url = `${this.domainService.apiBaseUrl}/publication-groups/${id}`;
        return this.http.get<PublicationGroup>(url).pipe(
            tap((publicationGroup) => {
                this.publicationGroupStore.upsert(publicationGroup.id, publicationGroup);
                this.publicationGroupStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getSinglePublicationFromGroup(id: ID, options: any = {}) {
        const url = `${this.domainService.apiBaseUrl}/publication-groups/${id}/publication`;
        this.publicationStore.setLoading(true);

        return this.http.get<Publication>(url, { params: options }).pipe(
            tap((publication) => {
                this.publicationStore.add(publication);
                this.publicationStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getLastUpdatedPublicationFromGroup(id: ID) {
        return this.getSinglePublicationFromGroup(id);
    }

    updatePublicationGroup(publicationGroup: PublicationGroup, data: any = null) {
        this.publicationGroupStore.setLoading(true);
        data = data ? data : publicationGroup;
        const url = `${this.domainService.apiBaseUrl}/publication-groups/${publicationGroup.id}`;
        const payload: RequestDto = new RequestDto(undefined, undefined, data);
        return this.http.patch<PublicationGroup>(url, payload.body).pipe(
            tap((publicationGroupData) => {
                this.publicationGroupStore.update(publicationGroup.id, publicationGroupData);
                this.publicationGroupStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    deletePublicationGroup(id: ID) {
        this.publicationGroupStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/publication-groups/${id}`;
        return this.http.delete<PublicationGroup>(url).pipe(
            tap(() => {
                this.publicationGroupStore.remove(id);
                this.publicationGroupStore.update((state) => ({
                    ...state,
                    totalDataLength: state.totalDataLength - 1,
                }));
                this.publicationGroupStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    clonePublicationGroup(id) {
        this.publicationGroupStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/publication-groups/${id}`;
        const payload: RequestDto = new RequestDto(null, null, id);
        return this.http.post<any>(url, payload.body).pipe(
            tap((publicationGroup) => {
                this.publicationGroupStore.add(publicationGroup);
                this.publicationGroupStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getUnapprovedSharedStorages(payload): Observable<PublicationGroup[]> {
        const url = `${this.domainService.apiBaseUrl}/shared-asset-storages/${payload.getParam(
            'sharedId'
        )}/publication-group/${payload.getParam('pubGroupId')}/unapproved`;

        return this.http.get<PublicationGroup[]>(url);
    }

    changeSearchField(search: string) {
        this.publicationGroupStore.update((publicationGroupState) => ({
            ui: { ...publicationGroupState.ui, search },
        }));
    }

    updateFilter(filterType, value) {
        const filters = {};
        filters[filterType] = value;
        this.publicationGroupStore.update((publicationGroupState) => ({
            ui: {
                ...publicationGroupState.ui,
                filters: { ...publicationGroupState.ui.filters, ...filters },
            },
        }));
    }

    setSorting(sort: SortingOption) {
        this.publicationGroupStore.update((publicationGroupState) => ({
            ui: { ...publicationGroupState.ui, sort },
        }));
    }

    changeViewOption(viewOption: number) {
        this.publicationGroupStore.update((publicationGroupState) => ({
            ui: { ...publicationGroupState.ui, viewOption },
        }));
    }
}
