import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './pages/login/login.component';
import { SharedModule } from '../modules/shared/shared.module';
import { MaterialModule } from '../modules/material/material.module';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginResolver } from './resolvers/login.resolver';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';

@NgModule({
    declarations: [LoginComponent, LoginFormComponent, MaintenanceComponent],
    imports: [CommonModule, SharedModule, MaterialModule],
    providers: [LoginResolver],
})
export class AuthModule {}
