import { Injectable } from '@angular/core';
import { DecodedToken } from '../models/decoded-token.model';
import { RoleKey } from '../../modules/shared/enums/roles.enum';
import { LocalStorageKeys } from '../../modules/shared/enums/local-storage-keys.enum';
import { jwtDecode } from 'jwt-decode';

@Injectable({
    providedIn: 'root',
})
export class JwtService {
    constructor() {}

    public decodeToken(token: string | null): DecodedToken {
        if (!token) {
            return { exp: 0, iat: 0, roles: [], username: '' };
        }

        return jwtDecode<DecodedToken>(token);
    }

    public getRolesFromToken(): RoleKey[] {
        const decodedToken = this.decodeToken(localStorage.getItem(LocalStorageKeys.Token) ?? '');
        return decodedToken?.roles ?? [];
    }

    public getTokenExpirationDate(token: string | null): Date | null {
        const decoded = this.decodeToken(token);
        if (decoded.exp === 0) {
            return null;
        }

        return new Date(decoded.exp * 1000);
    }

    public getUsernameFromToken(token: string | null): string {
        return this.decodeToken(token).username;
    }

    public getTokenLifeTime(token: string | null): number {
        const decodedToken = this.decodeToken(token);

        return decodedToken.exp !== 0 ? (decodedToken.exp - decodedToken.iat) * 1000 : 0;
    }

    public isTokenExpired(token: string | null): boolean {
        const decodedToken = this.decodeToken(token);

        if (decodedToken.exp === 0) {
            return true;
        }

        return decodedToken.exp * 1000 < Date.now();
    }

    public getTokenExpirationTime(token: string | null = '', offset = 0): Date {
        const tokenExpirationDate = this.getTokenExpirationDate(token);

        return tokenExpirationDate ? new Date(tokenExpirationDate.getTime() + offset) : new Date();
    }
}
