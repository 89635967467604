import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { SidebarLeftComponent } from './components/sidebar-left/sidebar-left.component';
import { SidebarRightComponent } from './components/sidebar-right/sidebar-right.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { SidebarWidgetComponent } from './components/sidebar-widget/sidebar-widget.component';
import { HtmlPipe } from './pipes/html.pipe';
import { StrlenPipe } from './pipes/strlen.pipe';
import { DragulaModule } from 'ng2-dragula';
import { NewLineToBreakPipe } from './pipes/newLineToBreak.pipe';
import { TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../../../environments/environment';

import { ScrollableSidebarWidgetComponent } from './components/scrollable-sidebar-widget/scrollable-sidebar-widget.component';
import { HotTableModule } from '@handsontable/angular';
import { MaterialModule } from '../material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { ModuleTitleComponent } from './components/module-title/module-title.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DeleteComponent } from './dialogs/delete/delete.component';
import { AssetBrowserButtonComponent } from '../../editor/asset-browser/components/asset-browser-button/asset-browser-button.component';
import { CustomStepperComponent } from './components/custom-stepper/custom-stepper.component';
import { CustomStepComponent } from './components/custom-stepper/custom-step/custom-step.component';
import { ViewOptionToggleComponent } from './components/view-option-toggle/view-option-toggle.component';
import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { ModuleContentCardComponent } from './components/module-content-card/module-content-card.component';
import { RouterModule } from '@angular/router';
import { ConfirmComponent } from './dialogs/confirm/confirm-component';
import { UserPipe } from './pipes/user.pipe';
import { ErrorComponent } from './dialogs/error/error.component';
import { AssetDetailsComponent } from './components/asset-details/asset-details.component';
import { DeactivateComponent } from './dialogs/deactivate/deactivate.component';
import localeDe from '@angular/common/locales/de';
import { QuicklinkModule } from 'ngx-quicklink';
import { InitDataResolver } from './resolvers/init-data.resolver';
import { SidebarTitleComponent } from './components/sidebar-title/sidebar-title.component';
import { AutoLogoutComponent } from './dialogs/auto-logout/auto-logout.component';
import { DateAdapter } from '@angular/material/core';
import { LoadingComponent } from './components/loading/loading.component';
import { ChannelRendererPipe } from './pipes/channelRenderer.pipe';
import { AssetStoragesPipe } from './pipes/asset-storages.pipe';
import { LayoutPipe } from './pipes/layout.pipe';
import { LocalePipe } from './pipes/locale.pipe';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { PlaceholderComponent } from './components/placeholder/placeholder.component';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { LinksModule } from '../links/links.module';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { SortingDropdownComponent } from './components/sorting-dropdown/sorting-dropdown.component';
import { DropdownMultipleComponent } from './components/dropdown-multiple/dropdown-multiple.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { InfiniteScrollingContainerDirective } from './directives/infinite-scrolling-container.directive';
import { InfiniteScrollingItemDirective } from './directives/infinite-scrolling-item.directive';
import { ScrollableContainerDirective } from './directives/scrollable-container.directive';
import { ScrollableItemDirective } from './directives/scrollable-item.directive';
import { AvatarComponent } from './components/avatar/avatar.component';
import { CollapsibleContentComponent } from './components/collapsible-content/collapsible-content.component';
import { UsersQuery } from './state/users/users.query';
import { IncomingMaintenanceComponent } from './dialogs/incoming-maintenance/incoming-maintenance.component';
import { BaseDialogComponent } from './dialogs/base-dialog/base-dialog.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { DataProcessingContentComponent } from './components/data-processing-content/data-processing-content.component';
import { SimpleConfirmComponent } from './dialogs/simple-confirm/simple-confirm-component';
import { AssetThumbnailComponent } from './components/asset-thumbnail/asset-thumbnail.component';
import { UpdateDetectedComponent } from './dialogs/update-detected/update-detected.component';
import { ImageComponent } from './components/image/image.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ChannelIconComponent } from './components/channel-icon/channel-icon.component';
import { StatusMessageComponent } from './components/status-message/status-message.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    const date = new Date();
    return new TranslateHttpLoader(http, environment.languageFileUrl, '.json?v=' + date.getTime());
}

@NgModule({
    imports: [
        CommonModule,
        LinksModule,
        RouterModule,
        TreeModule,
        DragulaModule.forRoot(),
        HotTableModule.forRoot(),
        TranslateModule.forChild(),
        MaterialModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        DropzoneModule,
        QuicklinkModule,
    ],
    declarations: [
        AvatarComponent,
        UserAvatarComponent,
        ScrollableSidebarWidgetComponent,
        SidebarLeftComponent,
        SidebarRightComponent,
        SidebarWidgetComponent,
        DeleteComponent,
        ConfirmComponent,
        AssetBrowserButtonComponent,
        HtmlPipe,
        SafeUrlPipe,
        NewLineToBreakPipe,
        StrlenPipe,
        LayoutPipe,
        ImagePreviewComponent,
        ModuleTitleComponent,
        CustomStepperComponent,
        CustomStepComponent,
        ViewOptionToggleComponent,
        FilterDropdownComponent,
        ModuleContentCardComponent,
        UserPipe,
        LocalePipe,
        ErrorComponent,
        AssetDetailsComponent,
        DeactivateComponent,
        SidebarTitleComponent,
        AutoLogoutComponent,
        LoadingComponent,
        ChannelRendererPipe,
        AssetStoragesPipe,
        PlaceholderComponent,
        LocalizedDatePipe,
        DropdownComponent,
        SortingDropdownComponent,
        DropdownMultipleComponent,
        ProgressBarComponent,
        InfiniteScrollingContainerDirective,
        InfiniteScrollingItemDirective,
        ScrollableContainerDirective,
        ScrollableItemDirective,
        CollapsibleContentComponent,
        IncomingMaintenanceComponent,
        BaseDialogComponent,
        TimeAgoPipe,
        DataProcessingContentComponent,
        SimpleConfirmComponent,
        AssetThumbnailComponent,
        UpdateDetectedComponent,
        ImageComponent,
        ChannelIconComponent,
        StatusMessageComponent,
    ],
    exports: [
        AvatarComponent,
        UserAvatarComponent,
        ScrollableSidebarWidgetComponent,
        SidebarLeftComponent,
        SidebarRightComponent,
        SidebarWidgetComponent,
        ImagePreviewComponent,
        DeleteComponent,
        ConfirmComponent,
        ModuleTitleComponent,
        AssetBrowserButtonComponent,
        CustomStepperComponent,
        CustomStepComponent,
        ViewOptionToggleComponent,
        FilterDropdownComponent,
        SortingDropdownComponent,
        DropdownMultipleComponent,
        ModuleContentCardComponent,
        HtmlPipe,
        SafeUrlPipe,
        NewLineToBreakPipe,
        StrlenPipe,
        TreeModule,
        TranslatePipe,
        DragulaModule,
        MaterialModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        DropzoneModule,
        UserPipe,
        LayoutPipe,
        LocalePipe,
        AssetDetailsComponent,
        DeactivateComponent,
        QuicklinkModule,
        SidebarTitleComponent,
        LoadingComponent,
        ChannelRendererPipe,
        AssetStoragesPipe,
        PlaceholderComponent,
        LocalizedDatePipe,
        ProgressBarComponent,
        InfiniteScrollingContainerDirective,
        InfiniteScrollingItemDirective,
        ScrollableContainerDirective,
        ScrollableItemDirective,
        CollapsibleContentComponent,
        BaseDialogComponent,
        TimeAgoPipe,
        DataProcessingContentComponent,
        SimpleConfirmComponent,
        AssetThumbnailComponent,
        UpdateDetectedComponent,
        ImageComponent,
        ChannelIconComponent,
        StatusMessageComponent,
    ],
    providers: [InitDataResolver],
})
export class SharedModule {
    constructor(
        private adapter: DateAdapter<any>,
        private translate: TranslateService,
        private usersQuery: UsersQuery
    ) {
        translate.addLangs(['en_US', 'de_CH']);
        translate.setDefaultLang('de_CH');
        translate.setDefaultLang('en_US');

        const browserLang = navigator.language.match(/de/) ? 'de_CH' : 'en_US';
        if (this.usersQuery.getLoggedInUser()) {
            const user = this.usersQuery.getLoggedInUser();
            if (user && user.locale) {
                this.translate.use(user.locale);
                this.adapter.setLocale(user.locale);
            } else {
                this.translate.use(browserLang);
                this.adapter.setLocale(browserLang);
            }
        } else {
            this.translate.use(browserLang);
            this.adapter.setLocale(browserLang);
        }

        registerLocaleData(localeDe);
    }
}
