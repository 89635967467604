import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { JwtService } from '../service/jwt.service';
import { AuthService } from '../service/auth.service';
import { TokenService } from '../service/token.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private authService: AuthService, private jwtService: JwtService, private tokenService: TokenService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkLogin(state.url);
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkLogin(state.url);
    }

    checkLogin(url: string): boolean {
        if (this.tokenService.isTokenExpired()) {
            this.authService.unattendedLogout(url);
            return false;
        }

        Sentry.setUser({ username: this.jwtService.getUsernameFromToken(this.tokenService.getToken()) });

        return true;
    }
}
