<div class="asset-browser-files" [ngClass]="{ loading: (assetsLoading$ | async) && (assetsLoaded$ | async) }">
    <div class="loading"></div>
    <div
        class="file-list"
        eliasInfiniteScrollingContainer
        (lastItemReached)="loadMoreAssets()"
        *ngIf="(assetsLoaded$ | async) && assets.length > 0"
        [ngClass]="{ 'fixed-height': fixedHeight, grid: viewValue === 0 }">
        <elias-asset-browser-file
            *ngFor="let asset of assets; trackBy: trackAssetById"
            [asset]="asset"
            [isSelected]="isSelected(asset)"
            [isSelectable]="fixedHeight"
            [isListView]="viewValue === 2"
            (assetClicked)="onAssetClick($event)"
            eliasInfiniteScrollingItem></elias-asset-browser-file>
    </div>
    <div
        class="no-files"
        *ngIf="(assetsLoaded$ | async) === false"
        [ngClass]="{ 'no-files-fixed-height': fixedHeight }">
        <elias-shared-loading [includeText]="true" descriptionContext="assetBrowser"></elias-shared-loading>
    </div>

    <div
        class="no-files"
        *ngIf="(assetsLoaded$ | async) && assets.length === 0"
        [ngClass]="{ 'no-files-fixed-height': fixedHeight }">
        {{ 'assetBrowser.nofiles' | translate }}
    </div>
</div>
